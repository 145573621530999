<template>
  <div class="language-switcher">
    <div class="language-switcher--selected" @click="isOpen = !isOpen">
      <i :class="`flag-sm flag-sm-${toLocaleFlag($i18n.locale)}`"></i>
      <span v-text="$t(`lang.${$i18n.locale}`)"></span>
      <span class="caret">&#9660;</span>
    </div>

    <div
      class="language-switcher--menu"
      v-if="isOpen"
      :class="{ 'is-open': isOpen }"
    >
      <div
        class="menu-item"
        v-for="locale in $i18n.availableLocales"
        :key="locale"
        @click="switchTo(locale)"
      >
        <i :class="`flag-sm flag-sm-${toLocaleFlag(locale)}`"></i>
        <span v-text="$t(`lang.${locale}`)"></span>
      </div>
    </div>
  </div>
</template>

<script>
const FLAG_OVERRIDES = {
  en: "GB"
};

export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    switchTo(lang) {
      this.$i18n.locale = lang;
      this.isOpen = false;

      localStorage.setItem("user-preferred-lang", lang);
    },
    toLocaleFlag(locale) {
      return FLAG_OVERRIDES[locale] ?? locale.toUpperCase();
    }
  }
};
</script>

<style lang="scss">
.language-switcher {
  position: relative;

  &--selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
    font-size: 14px;

    .flag-sm {
      margin-right: 4px;
    }

    .caret {
      margin-left: 2px;
      font-size: 10px;
      transform: scale(1, 0.5);
    }
  }

  &--menu {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 2;
    padding: 4px 0;

    .menu-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: white;
      color: #102a43;
      padding: 8px 12px;
      transition: background-color 0.3s ease;
      white-space: nowrap;
      font-size: 14px;

      .flag-sm {
        margin-right: 4px;
      }

      &:hover {
        background-color: darken(white, 10%);
      }
    }
  }
}
</style>

<template>
  <div id="root">
    <img v-if="imageUrl" style="width: 100%" :src="imageUrl" />
    <header class="container" v-if="!imageUrl">
      <div class="row">
        <div class="column">
          <div class="logo">
            <img src="./assets/images/logo-aiaibot.svg" alt="aiaibot Logo" />
            <LanguageSwitcher></LanguageSwitcher>
          </div>
        </div>
      </div>
    </header>
    <div class="hero" v-if="!imageUrl">
      <div class="container" v-if="!key">
        <form class="set-aiaibot" @submit.prevent="setConfig">
          <input
            :placeholder="$t('form.script.placeholder')"
            v-model="input"
            type="text"
          />
          <button v-text="$t('cta.preview')"></button>
        </form>
      </div>

      <div class="container set-url" v-if="key">
        <div class="row">
          <h2 v-if="title">{{ title }}</h2>
          <h4 v-if="subtitle">{{ subtitle }}</h4>
          <div v-if="showInputUrl" class="description" v-html="$t('form.url.description')" />
          <form class="row" @submit.prevent="setUrl" v-if="showInputUrl">
            <div class="ten columns">
              <input
                :placeholder="$t('form.url.placeholder')"
                v-model="url"
                type="text"
                class="columns"
              />
            </div>
            <button class="two columns" v-text="$t('cta.set_url')"></button>
          </form>
        </div>
        <div class="row">
          <div class="events" v-if="events.length">
            <ul>
              <li v-for="(eventName, index) in events" :key="`event-${index}`" @click.prevent="triggerEvent(eventName)">{{eventName}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
  components: { LanguageSwitcher },
  data() {
    return {
      title: "",
      subtitle: "",
      url: "",
      input: "",
      hostname: "chat.aiaibot.com",
      protocol: "https",
      key: "",
      storyId: "",
      storyStatus: "published",
      showInputUrl: true
    };
  },
  beforeMount() {
    const params = new URLSearchParams(location.search);
    this.title = params.get("title") || "";
    this.subtitle = params.get("subtitle") || "";
    this.key = params.get("key") || "";
    this.storyId = params.get("storyId") || "";
    this.storyStatus = params.get("storyStatus") || "published";
    this.showInputUrl = params.get("hideInput") === null && this.storyId === "";
    this.protocol = params.get("protocol") || this.protocol;
    this.hostname = params.get("hostname") || this.hostname;
    this.imageUrl = params.get("image");
    this.mountPoint = params.get("mountPoint")
    this.events = params.get("events") ? params.get("events").split(','): [];
  },
  mounted() {
    this.url = location.pathname;

    if (this.key) {
      const root = document.querySelector("#root");
      const aiaibot = document.createElement("script");
      aiaibot.setAttribute("type", "text/javascript");

      aiaibot.setAttribute(
        "src",
        this.protocol + "://" + this.hostname + "/bootstrap.js"
      );
      aiaibot.setAttribute("data-aiaibot-key", this.key);

      if (this.storyId) {
        aiaibot.setAttribute("data-aiaibot-story-id", this.storyId);
        aiaibot.setAttribute("data-aiaibot-story-status", this.storyStatus);
      }

      if (this.mountPoint) {
        aiaibot.setAttribute("data-aiaibot-mount-point", this.mountPoint);
      }

      root.appendChild(aiaibot);
    }
  },
  methods: {
    setConfig() {
      const matches = this.input.match(
        /<script.*src="(http|https):\/\/([a-zA-Z0-9-.]+).*".*data-aiaibot-key="([a-zA-Z0-9-]+)"/
      );
      const protocol = matches[1];
      const hostname = matches[2];
      const key = matches[3];

      const params = new URLSearchParams(location.search);
      params.set("protocol", protocol);
      params.set("hostname", hostname);
      params.set("key", key);

      window.history.replaceState({}, "", `${location.pathname}?${params}`);

      location.reload();
    },

    setUrl() {
      const params = new URLSearchParams(location.search);

      let url = this.url;
      try {
        url = new URL(url).pathname;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      window.history.replaceState({}, "", `${url}?${params}`);
      location.reload();
    },

    triggerEvent(eventName) {
      window.aiaibot.close();
      const aiaibotEvent = new Event(eventName);
      document.dispatchEvent(aiaibotEvent);
    }
  }
};
</script>

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: require("@/locales/en.json"),
  de: require("@/locales/de.json")
};

const numberFormats = {
  "en-US": {
    decimal: {
      style: "decimal"
    },
    percent: {
      style: "percent"
    }
  },
  "de-DE": {
    decimal: {
      style: "decimal"
    },
    percent: {
      style: "percent"
    }
  }
};

function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };

  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  return trimmedLocale;
}

function getStartingLocale() {
  const supportedLangs = Object.keys(messages);

  const preferredLanguage = localStorage.getItem("user-preferred-lang");
  if (supportedLangs.includes(preferredLanguage)) {
    return preferredLanguage;
  }

  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLangs.includes(browserLocale)) {
    return browserLocale;
  }

  return "en";
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: "en",
  messages,
  numberFormats
});
